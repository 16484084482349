
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { date } from "yup/lib/locale";
import { useStore } from "vuex";

import "emoji-picker-element";
import { Loading } from "@element-plus/icons-vue";

const store = useStore();

export default defineComponent({
  name: "Actualités",
  components: {},
  setup() {
    const store = useStore();

    const count = ref(0);
    const load = () => {
      count.value += 2;
    };
    const studentID = window.localStorage.getItem("activeStudent");

    const { t } = useI18n();
    const actualitiesData = ref<any>([]);
    const totalNewPosts = ref(0);
    const apiUrl = store.getters.serverConfigUrl.base_url + "/";
    const commentsRow = ref<any[]>([]);
    const ParentId = ref(window.localStorage.getItem("parentId"));

    const visibleLikes = ref<boolean>(false);
    const loadingLikes = ref<boolean>(true);
    const loadingActuality = ref<boolean>(true);
    const likes = ref<any[]>([]);

    const fetchData = () => {
      ApiService.get("/actuality/parents/" + studentID)
        .then(({ data }) => {
          commentsRow.value = [];
          let filterdData: any = [];
          data.forEach((element: any) => {
            commentsRow.value.push("");
            let photos = element.photos.map((photo) => {
              return apiUrl + photo;
            });
            element.photos = photos;
            element.processing = false;
            element.showComments = true;
            filterdData.push(element);
          });
          actualitiesData.value = filterdData;
        })
        .catch((e) => console.log(e))
        .finally(() => (loadingActuality.value = false));
    };

    onMounted(() => {
      setCurrentPageTitle("Actualités");
      fetchData();
    });

    const submitComment = (
      comment: string,
      actualityId: string,
      actualityIndex: number
    ) => {
      if (comment == "" || comment == " " || !comment) return;

      const request: any = {
        desc: comment,
        actuality: actualityId,
        type: "Parent",
        parent: window.localStorage.getItem("parentId"),
      };
      const user = store.state.AuthModule.user;
      actualitiesData.value[actualityIndex].comments.push({
        ...request,
        fullName: user.father.firstName + " " + user.father.lastName,
        parent: { photo: user.photo },
      });
      commentsRow.value = [];
      ApiService.post("/actuality/comment", { ...request })
        .then(({ data }) => {
          fetchData();
        })
        .catch((e) => console.log(e));
    };

    const submitLike = (actualityId: string, actualityIndex: number) => {
      actualitiesData.value[actualityIndex].processing = true;
      const request: any = {
        actuality: actualityId,
        type: "Parent",
        parent: window.localStorage.getItem("parentId"),
      };
      actualitiesData.value[actualityIndex].liked = true;
      actualitiesData.value[actualityIndex].total_likes++;
      ApiService.post("/actuality/like", { ...request })
        .then(({ data }) => {
          //fetchData();
        })
        .catch((e) => {
          console.log(e);
          actualitiesData.value[actualityIndex].liked = false;
          actualitiesData.value[actualityIndex].total_likes--;
        })
        .finally(
          () => (actualitiesData.value[actualityIndex].processing = false)
        );
    };

    const submitUnlike = (actualityId: string, actualityIndex: number) => {
      actualitiesData.value[actualityIndex].liked = false;
      actualitiesData.value[actualityIndex].total_likes--;
      actualitiesData.value[actualityIndex].processing = true;
      ApiService.delete("/actuality/like/remove/" + actualityId)
        .then(({ data }) => {
          actualitiesData.value[actualityIndex].liked = false;
        })
        .catch((e) => {
          console.log(e);
          actualitiesData.value[actualityIndex].liked = true;
          actualitiesData.value[actualityIndex].total_likes++;
        })
        .finally(
          () => (actualitiesData.value[actualityIndex].processing = false)
        );
    };

    const deleteComment = (idComment: string) => {
      if (!idComment) return;
      ApiService.delete("/actuality/comment/" + idComment)
        .then(({ data }) => {
          fetchData();
        })
        .catch((e) => console.log(e));
    };

    const toggleComment = (actualityIndex: number) => {
      actualitiesData.value[actualityIndex].showComments =
        !actualitiesData.value[actualityIndex].showComments;
    };

    const showWhoLiked = (actuality: any) => {
      loadingLikes.value = true;
      visibleLikes.value = true;
      ApiService.get("/actuality/likes/" + actuality._id)
        .then(({ data }) => {
          likes.value = data.likes;
          loadingLikes.value = false;
        })
        .catch((e) => console.log(e));
    };

    const toggleEmojis = (actualityIndex: number) => {
      actualitiesData.value[actualityIndex].emojis =
        !actualitiesData.value[actualityIndex].emojis;
    };

    const setEmoji = (emoji, index: number) => {
      commentsRow.value[index] =
        commentsRow.value[index] + emoji.detail.unicode;
      toggleEmojis(index);
    };
    const onSelectEmoji = (emoji, index: number) => {
      commentsRow.value[index] =
        commentsRow.value[index] + emoji.detail.unicode;
      toggleEmojis(index);
    };

    return {
      count,
      store,
      load,
      date,
      apiUrl,
      moment,
      actualitiesData,
      totalNewPosts,
      t,
      commentsRow,
      submitComment,
      submitLike,
      submitUnlike,
      deleteComment,
      ParentId,
      toggleComment,
      visibleLikes,
      likes,
      showWhoLiked,
      toggleEmojis,
      setEmoji,
      onSelectEmoji,
      loadingLikes,
      loadingActuality,
    };
  },
});
